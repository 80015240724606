<template>
    <el-main>
        <div v-loading="loading" v-if="loading"></div>
        <div v-if="!loading">
            <el-form>
                <el-form-item label="Enable">
                    <el-switch v-model="formData.enable_chatgpt_integration" @change="handleSubmit"></el-switch>
                </el-form-item>
                <div v-if="formData.enable_chatgpt_integration == true">
                    <el-form-item label="Instructions">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 10, maxRows: 20}"
                            style="border: 1px solid #cacaca"
                            placeholder="Please input"
                            v-model="formData.properties.instructions">
                        </el-input>
                        <small>Supported variables: [company_name], [address], [hours_of_operation], [website], [phone_number], [appointment_url], [credit_application_url]</small>
                        <small>Estimated tokens: <strong>{{ countTokens(formData.properties.instructions) }}</strong>; Characters: <strong>{{ formData.properties.instructions!== null ? formData.properties.instructions.length : 0 }}</strong>. Click <a href="https://platform.openai.com/tokenizer" target="_blank">here</a> to check the actual token count</small>
                    </el-form-item>
                    <el-form-item label="Offering">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 10, maxRows: 20}"
                            style="border: 1px solid #cacaca"
                            placeholder="Please input"
                            v-model="formData.properties.offering">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Not Offering">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 10, maxRows: 20}"
                            style="border: 1px solid #cacaca"
                            placeholder="Please input"
                            v-model="formData.properties.not_offering">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Hours of operation">
                        <el-input
                            type="text"
                            placeholder="Please input hours of operation"
                            v-model="formData.properties.hours_of_operation">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Minutes to wait until chatgpt turn overs">
                        <el-input
                            type="number"
                            placeholder="Please input minutes"
                            v-model="formData.properties.minutes_to_wait">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Response if the contact says no">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.if_no">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Response if the contact wants to make a call">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.if_call">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Sequence to enroll if no response in 15 minutes">
                        <el-select
                            v-model="formData.properties.sequence_id_no_response"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="sequence in sequenceList"
                                    :key="sequence.id"
                                    :label="'(#' + sequence.id + ') ' + sequence.name"
                                    :value="sequence.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="ACTIVATE ChatGPT disposition status">
                        <el-select
                            v-model="formData.properties.disposition_status_id_activate"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="dispositionStatus in dispositionStatusList"
                                    :key="dispositionStatus.id"
                                    :label="'(#' + dispositionStatus.id + ') ' + dispositionStatus.name"
                                    :value="dispositionStatus.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="PAUSE ChatGPT disposition status">
                        <el-select
                            v-model="formData.properties.disposition_status_id_pause"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="dispositionStatus in dispositionStatusList"
                                    :key="dispositionStatus.id"
                                    :label="'(#' + dispositionStatus.id + ') ' + dispositionStatus.name"
                                    :value="dispositionStatus.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="User assigned">
                        <el-select
                            v-model="formData.properties.user_id"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="user in usersList"
                                    :key="user.id"
                                    :label="'(#' + user.id + ') ' + user.name"
                                    :value="user.id"
                                    v-if="user.token !== ''">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="POC when no owner asigned">
                        <el-select
                            v-model="formData.properties.poc_id"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="user in usersList"
                                    :key="user.id"
                                    :label="'(#' + user.id + ') ' + user.name"
                                    :value="user.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Line to use">
                        <el-select
                            v-model="formData.properties.line_id"
                            class="w-100"
                            clearable>
                            <el-option
                                v-for="line in linesList"
                                    :key="line.id"
                                    :label="'(#' + line.id + ') ' + line.name"
                                    :value="line.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Lines to Block">
                        <el-select
                            v-model="formData.properties.blocked_lines"
                            class="w-100"
                            multiple
                            clearable>
                            <el-option
                                v-for="line in linesList"
                                    :key="line.id"
                                    :label="'(#' + line.id + ') ' + line.name"
                                    :value="line.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!--<<el-form-item label="Sarah speaks your inventory">
                        <el-checkbox
                            v-model="formData.properties.include_inventory"
                        >Yes / No</el-checkbox>
                    </el-form-item>
                    el-form-item label="1st followup message">
                        <el-input
                            type="text"
                            placeholder="Please input message"
                            v-model="formData.properties.first_followup_msg">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="2nd followup message">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.second_followup_msg">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="3rd followup message">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.third_followup_msg">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="4th followup message">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.fourth_followup_msg">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="5th followup message">
                        <el-input
                            type="text"
                            placeholder="Please input response"
                            v-model="formData.properties.fifth_followup_msg">
                        </el-input>
                    </el-form-item>-->
                    <el-form-item class="bottom text-right">
                        <el-button class="button" type="primary" @click="handleSubmit">save</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </el-main>
</template>
<script>
import store from "../../../store"
import { mapState } from 'vuex'

export default {
    name: 'Chat-GPT-Integration',
    data() {
        return {
            company: {},
            sequenceList: [],
            dispositionStatusList: [],
            linesList: [],
            usersList: [],
            formData: {
                enable_chatgpt_integration: false,
                properties: {
                    instructions: 'Generate an appointment or a phone call through SMS and introduce yourself a  Sales Concierge namely Sarah at [company_name]. Your goal is to set up an appointment for today, schedule a phone call, or get a credit application filled out. You can assist the remote person with a car he needs and wants. Give directions to the dealership after every question. We finance every year make and model of certified quality car up to 96 months and all credits ranging from 350 to 850 score including Buy Here Pay Here with little to zero down payment and interest rates as low as 0% zero percent.  We give top dollar for your trade-in vehicle even if it doesn\'t run. Do not repeat sentences. Ask for the year make and model of the car customer is interested in. Do not need drivers license to buy a car. Ask repeatedly to set up an appointment. When the phrase "add appointment to calender" is mentioned, thank customer for the appointment and STOP conversation.  Assist a customer in scheduling a test drive for a specific model they\'re interested in Explain the process of financing a car purchase and the various options available Provide guidance to a customer seeking information about trading in their old vehicle for a new one.Phone number: [phone_number].Website: [website].[company_name] Hours: [hours_of_operation]. Address: [address]',
                    offering: '',
                    not_offering: '',
                    hours_of_operation: '8 AM-7 PM Sunday to Friday',
                    minutes_to_wait: 5,
                    if_no: 'Would you like to call us at this number to further discuss?',
                    if_call: 'ok great, let me find you with a sales person who can assist you with your request',
                    sequence_id_no_response: '',
                    disposition_status_id_activate: '',
                    disposition_status_id_pause: '',
                    line_id: '',
                    blocked_lines: [],
                    user_id: '',
                    user_token: '',
                    poc_id: '',
                    include_inventory: '',
                    first_followup_msg: 'Hi [first_name], did you get my last text?',
                    second_followup_msg: 'Hi [first_name], did you get my last text?',
                    third_followup_msg: 'Hi [first_name], did you get my last text?',
                    fourth_followup_msg: 'Hi [first_name], did you get my last text?',
                    fifth_followup_msg: 'Hi [first_name], did you get my last text?'
                },
            },
            chatgptModelsList: [
                {
                    id: 'ft:gpt-3.5-turbo-0613:simpsocial-llc::8Id7kH1B',
                    name: 'GPT 3.5 (ft:gpt-3.5-turbo-0613:simpsocial-llc::8Id7kH1B)'
                },{
                    id: 'gpt-4-0613',
                    name: 'GPT 4 (gpt-4-0613)'
                }],
            loading: false,
        }
    },
    computed: mapState(['currentCompany']),
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)

        this.formData.properties.offering = "1. We do accept trade-ins nationwide and if need be help get a duplicate title! You can get a free trade evaluation as well.\n";
        this.formData.properties.offering += "2. We finance every type of credit.\n";
        this.formData.properties.offering += "3. Down payment varies based on the situation and the finance plan you choose. With our 40 lender partnerships, we're confident we can secure the best finance options. We're committed to creating the best possible scenario for you.\n";
        this.formData.properties.offering += "4. We strive to provide the best negotiated price to our customers. Our team is always ready to discuss and work with you to ensure the final out the door price fits your budget.\n";
        this.formData.properties.offering += "5. We understand that the payment budget and interest rates are crucial factors. We can create a customized loan program based on your down payment and financing rate to fit your needs.\n";
        this.formData.properties.offering += "6. We carry Sedans, SUV, Hatchback, Trucks, Coupe as well as convertibles.\n";
        this.formData.properties.offering += "7. We prefer to discuss prices, taxes, doc fees, and any other fees assoclated with the purchase by saying a team member will reach out to you for a quick phone discussion about more details?\n";
        this.formData.properties.offering += "8. We provide customers credit application URL and the URL is [credit_application_url]\n";

        this.formData.properties.not_offering = "1. We do not offer Buy Here Pay Here.\n";
        this.formData.properties.not_offering += "2. We do not require a driver's license to purchase a car.\n";
        this.formData.properties.not_offering += "3. We do not sell Salvage title cars.\n";

        this.getLatestCompanyIntegration()
        this.getCompanyDispositionStatuses()
        this.getCompanyLines()
        this.getCompanyUsers()
    },
    methods: {
        getLatestCompanyIntegration() {
            this.axios.get(`/api/company/simpid-${this.company.id}/integration/chatgpt`).then(response => {
                if( typeof response.data.status !== undefined ) {
                    this.formData.enable_chatgpt_integration = response.data.status !== 1 ? false : true
                    if( response.data.properties !== null ) {

                        if( response.data.properties.instructions !== null ) {
                            this.formData.properties.instructions = response.data.properties.instructions
                        }

                        if( response.data.properties.offering !== null && response.data.properties.offering !== undefined ) {
                            this.formData.properties.offering = response.data.properties.offering
                        }
                        if( response.data.properties.not_offering !== null && response.data.properties.not_offering !== undefined ) {
                            this.formData.properties.not_offering = response.data.properties.not_offering
                        }

                        this.formData.properties.hours_of_operation = response.data.properties.hours_of_operation
                        this.formData.properties.minutes_to_wait = response.data.properties.minutes_to_wait
                        this.formData.properties.if_no = response.data.properties.if_no
                        this.formData.properties.if_call = response.data.properties.if_call
                        this.formData.properties.sequence_id_no_response = response.data.properties.sequence_id_no_response
                        this.formData.properties.disposition_status_id_activate = response.data.properties.disposition_status_id_activate
                        this.formData.properties.disposition_status_id_pause = response.data.properties.disposition_status_id_pause
                        this.formData.properties.line_id = response.data.properties.line_id
                        this.formData.properties.blocked_lines = response.data.properties.blocked_lines
                        this.formData.properties.include_inventory = response.data.properties.include_inventory
                        this.formData.properties.first_followup_msg = response.data.properties.first_followup_msg
                        this.formData.properties.second_followup_msg = response.data.properties.second_followup_msg
                        this.formData.properties.third_followup_msg = response.data.properties.third_followup_msg
                        this.formData.properties.fourth_followup_msg = response.data.properties.fourth_followup_msg
                        this.formData.properties.fifth_followup_msg = response.data.properties.fifth_followup_msg
                        this.formData.properties.user_id = response.data.properties.user_id
                        this.formData.properties.user_token = response.data.properties.user_token
                        this.formData.properties.poc_id = response.data.properties.poc_id

                        this.getAlowareSequences()
                    }
                }
            })
        },
        getAlowareSequences() {
            this.axios.get(`/api/company/simpid-${this.company.id}/aloware-sequences`).then(response => {
                if( response.data.success !== true ) {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                } else {
                    this.sequenceList = response.data.data

                    if( this.formData.properties.sequence_id_no_response !== '' ) {
                        this.sequenceList.forEach((v) => {
                            if( v.id == this.formData.properties.sequence_id_no_response ) {
                                this.formData.properties.sequence_id_no_response = v.id
                            }
                        })
                    }
                }
            })
        },
        getCompanyDispositionStatuses() {
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-disposition-statuses')
            .then(response => {
                if (response.data.success == true) {
                    this.dispositionStatusList = response.data.data

                    if( this.formData.properties.disposition_status_id_activate !== '' ) {
                        this.dispositionStatusList.forEach((v) => {
                            if( v.id == this.formData.properties.disposition_status_id_activate ) {
                                this.formData.properties.disposition_status_id_activate = v.id
                            }
                        })
                    }

                    if( this.formData.properties.disposition_status_id_pause !== '' ) {
                        this.dispositionStatusList.forEach((v) => {
                            if( v.id == this.formData.properties.disposition_status_id_pause ) {
                                this.formData.properties.disposition_status_id_pause = v.id
                            }
                        })
                    }
                }
            });
        },
        getCompanyLines() {
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-lines')
            .then(response => {
                if (response.data.success == true) {
                    this.linesList = response.data.data

                    if( this.formData.properties.line_id !== '' ) {
                        this.linesList.forEach((v) => {
                            if( v.id == this.formData.properties.line_id ) {
                                this.formData.properties.line_id = v.id
                            }
                        })
                    }
                }
            });
        },
        getCompanyUsers() {
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-users')
            .then(response => {
                if (response.data.success == true) {
                    this.usersList = response.data.data

                    if( this.formData.properties.user_id !== '' ) {
                        this.usersList.forEach((v) => {
                            if( v.id == this.formData.properties.user_id ) {
                                this.formData.properties.user_id = v.id
                                this.formData.properties.user_token = v.token
                            }
                        })
                    }
                }
            });
        },
        handleSubmit() {
            const formatted_offering = this.formatTextarea(this.formData.properties.offering);
            const formatted_not_offering = this.formatTextarea(this.formData.properties.not_offering);
            this.axios.post(`api/company/${this.company.id}/integration/chatgpt`, {
                status: this.formData.enable_chatgpt_integration,
                properties: {
                    instructions: this.formData.properties.instructions,
                    offering: formatted_offering,
                    not_offering: formatted_not_offering,
                    hours_of_operation: this.formData.properties.hours_of_operation,
                    minutes_to_wait: this.formData.properties.minutes_to_wait,
                    if_no: this.formData.properties.if_no,
                    if_call: this.formData.properties.if_call,
                    sequence_id_no_response: this.formData.properties.sequence_id_no_response,
                    disposition_status_id_activate: this.formData.properties.disposition_status_id_activate,
                    disposition_status_id_pause: this.formData.properties.disposition_status_id_pause,
                    line_id: this.formData.properties.line_id,
                    blocked_lines: this.formData.properties.blocked_lines,
                    include_inventory: this.formData.properties.include_inventory,
                    first_followup_msg: this.formData.properties.first_followup_msg,
                    second_followup_msg: this.formData.properties.second_followup_msg,
                    third_followup_msg: this.formData.properties.third_followup_msg,
                    fourth_followup_msg: this.formData.properties.fourth_followup_msg,
                    fifth_followup_msg: this.formData.properties.fifth_followup_msg,
                    user_id: this.formData.properties.user_id,
                    user_token: this.formData.properties.user_token,
                    poc_id: this.formData.properties.poc_id
                },
            }).then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Company has been successfully Updated",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
        },
        countTokens(text) {
            // Split the text into tokens using space as a separator
            if( text !== null ) {
                const tokens = text.trim().split(/\s+/);

                // Count the number of tokens
                const tokenCount = tokens.length;

                return tokenCount;
            }
            return 0;
        },
        formatTextarea(value) {
            if(value) {
                return value.replace(/\r\n|\r|\n/g, '\n');
            }
            return value;
        }
    },
    watch: {
        'formData.properties.user_id': function(val, oldVal) {
            this.usersList.forEach((v) => {
                if( v.id == this.formData.properties.user_id ) {
                    this.formData.properties.user_token = v.token
                }
            })
        },
        currentCompany(newValue, oldValue) {
            this.company = JSON.parse(newValue)
        },
    },
}
</script>
