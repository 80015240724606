<template>
    <el-main>
        <div v-loading="loading" v-if="loading"></div>
        <div v-if="!loading">
            <el-form>
                <el-form-item label="WEB MAIL">
                    <el-switch
                        v-model="formData.webmail_enabled"
                        @change="handleWebmailChange"
                    ></el-switch>
                </el-form-item>
                <el-select v-model="web_provider"
                    placeholder="Select Web Provider">
                    <el-option
                    v-for="item in web_provider_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                        <span style="float: left">{{ item.name }}</span>
                    </el-option>
                </el-select>
                <br><br>
                <div v-if="web_provider=='dealer-com'">
                    Website Chat
                    <br>
                    <small>Paste the code below before the closing of the head tag</small>

                    <textarea style="width: 100%;height: 50px;"><script src="{{ company.url }}/web-chat-dealer-com.js"></script></textarea>
                </div>
                <div v-else>
                    Website Chat
                    <br>
                    <small>Paste the code below before the closing of the head tag</small>

                    <textarea style="width: 100%;height: 50px;"><script src="{{ company.url }}/chat-popup.js"></script></textarea>

                    <br><br>
                    VDP Chat
                    <br>
                    <small>1. Paste the code below before the closing of the head tag</small>

                    <textarea style="width: 100%;height: 50px;"><script src="{{ company.url }}/vdp-chat.js"></script></textarea>
                    <br>
                    <small>2. Add the code below where you want the button to show. Replace the variables with correct values</small>
                    <textarea id="" style="width: 100%;height: 50px;"><div class="simpwebchat_inv_item" data-year="[YEAR]" data-price="[PRICE]" data-make="[MAKE]" data-model="[MODEL]" data-stock_no="[STOCK_NO]" data-vin="[VIN]"></div></textarea>

                    <br><br>
                    SRP Chat
                    <br>
                    <small>1. Paste the code below before the closing of the head tag</small>

                    <textarea style="width: 100%;height: 50px;"><script src="{{ company.url }}/srp-chat.js"></script></textarea>
                    <br>
                    <small>2. Add the code below where you want the button to show. Replace the variables with correct values</small>
                    <textarea id="" style="width: 100%;height: 50px;"><div class="simpwebchat_srp_item" data-year="[YEAR]" data-price="[PRICE]" data-make="[MAKE]" data-model="[MODEL]" data-stock_no="[STOCK_NO]" data-vin="[VIN]"></div></textarea>
                </div>
                <br><br>
                Webhooks<br>

                Voucher
                <br>
                <textarea style="width: 100%;height: 20px;">{{ company.url }}/webhook/voucher</textarea>
            </el-form>
        </div>
        <el-dialog
            title="Confirm Action"
            :visible.sync="confirmWebmailSetupVisible"
            width="30%"
            :before-close="handleClose">
            <span>Are you sure you want to activate webmail? The domain will be auto registered to AWS and account will be charged 12USD</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleWebmailSetupChange('cancel')">Cancel</el-button>
                <el-button type="primary" @click="handleWebmailSetupChange('confirm')">Confirm</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
<script>
import store from "../../../store"
import { mapState } from 'vuex'

export default {
    name: 'Webchat-Homepage',
    data() {
        return {
            company: {},
            loading: false,
            formData: {
                webmail_enabled: false,
            },
            confirmWebmailSetupVisible: false,
            web_provider: '',
            web_provider_options: [
                {
                    id: '',
                    name: 'Default web provider'
                },
                {
                    id: 'dealer-com',
                    name: 'dealer.com'
                },
            ],
        }
    },
    computed: mapState(['currentCompany']),
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.formData.webmail_enabled = this.company.webmail_enabled
    },
    methods: {
        handleWebmailChange(value) {
            //alert if value is true but domain setup not exist
            if( this.company.email_setup === null && value === true) {
                this.confirmWebmailSetupVisible = true
            } else {
                this.handleWebmailUpdate();
            }
        },
        handleClose() {
            this.company.webmail_enabled = false
        },
        handleWebmailSetupChange(action) {
            this.confirmWebmailSetupVisible = false
            if(action == 'cancel') {
                this.company.webmail_enabled = false
            } else {
                this.handleWebmailUpdate();
            }
        },
        handleWebmailUpdate() {
            this.company = {
                ...this.company,
                webmail_enabled: this.formData.webmail_enabled
            }
            this.axios
                .put(`/api/company/simpid-${this.company.id}`, this.company)
                .then(response => {
                    if (response.data.success == true) {
                        store.commit(
                            "UpdateCurrentCompany",
                            response.data.data
                        );
                        this.$notify({
                            title: "Success",
                            message: "Company has been successfully Updated",
                            type: "success"
                        });
                    } else {
                        let errors = response.data.errors.message
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n"
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                });
        },
    },
    watch: {
        currentCompany(newValue, oldValue) {
            this.company = JSON.parse(newValue)

            this.formData.webmail_enabled = this.company.webmail_enabled
        },
    },
}
</script>
